import React, { useState } from 'react'
import { ButtonGroup, IconButton, Tooltip, Typography, Tabs, Tab, } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import FileLegajoService from '../../../../services/fileLegajo';
import CustomButton from '../../general/CustomButton';
import CustomInput from '../../general/CustomInput';
import CustomSelect from '../../general/CustomSelect';
import { simpleAlerts } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/codesHTTP';
import { formatDate } from '../../../../utils/function';
import _ from 'lodash';

const columns = [
    { id: 'id_archivoLegajo', label: '#', width: 50, align: 'center', },
    { id: 'tipoLegajo', label: 'Tipo', width: 200, align: 'left', },
    { id: 'fecha', label: 'Fecha Registro', width: 120, align: 'center', },
    { id: 'fechaMod', label: 'Ult. Modificacion', width: 80, align: 'center', },
    { id: 'nota', label: 'Nota', width: 100, align: 'left', },
    { id: 'usuario', label: 'Usuario', width: 80, align: 'left', },
    { id: 'actions', label: <ConstructionIcon key="icon" fontSize='small' />, width: 50, align: 'center', },
];

const columnsHistorial = [
    { id: 'id_archivoLegajo', label: 'cod', width: 100, align: 'left' },
    { id: 'tipoLegajo', label: 'Tipo', width: 100, align: 'left' },
    { id: 'fecha', label: 'Fec. Reg.', width: 150, align: 'left', edit: true, type: 'date' },
    { id: 'fechaMod', label: 'Fec Reg.', width: 150, align: 'center', format: (value) => formatDate(new Date(value), 'date-time', 'short'), },
    { id: 'fechaAUD', label: 'Fec. Modif.', width: 200, align: 'center', format: (value) => formatDate(new Date(value), 'date-time', 'short'), },
    { id: 'tipoAUD', label: 'Estado', width: 80, align: 'center' },
    { id: 'usuario', label: 'Usuario', width: 80, align: 'center' },
];

const InformationVerifyLegajo = ({
    getInfo, dateInit, setDateInit, dateEnd, setDateEnd,
    dataType, valueType, setValueType,
    valueTypeLegajo, setValueTypeLegajo, typeLegajo, detailLegajo, setDetailLegajo, detailLegajoAUD,
    setOpenLoader, setTitleLoader, historical, handleHistorical
}) => {
    let rowColor = 'table-warning';

    const downloadFilesLegajo = async (info) => {
        try {
            setOpenLoader(true);
            setTitleLoader('Descargando documento ...')
            const result = await FileLegajoService.downloadFilesLegajo(info?.rutaArchivo);
            if (result.status === CODES.SUCCESS_200) {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', info?.rutaArchivo);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            } else {
                simpleAlerts('Error al descargar el archivo.', 'error');
            }
        } catch (error) {
            console.log(`Error al descargar el archivo: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null)
        }
    }

    const deleteFileLegajo = async (info) => {
        try {
            setOpenLoader(true);
            setTitleLoader('Eliminando documento ...')
            const result = await FileLegajoService.deleteFile(info.id_archivoLegajo);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message, 'success');
                getInfo();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error al eliminar el documento.', 'error');
            }
        } catch (error) {
            console.log(`Error al descargar el documento: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null)
        }
    }

    const [imageUrl, setImageUrl] = useState(null);
    const viewFileS3_0 = async (info) => {
        try {
            const response = await FileLegajoService.viewFileS3(info.rutaArchivo);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setImageUrl(url);
        } catch (error) {
            console.error('Error al visualizar la imagen:', error);
        }
    };

    const viewFileS3 = async (info) => {
        try {
            let updData = _.cloneDeep(detailLegajo);
            const idx = updData.findIndex(e => +e.id_archivoLegajo === +info.id_archivoLegajo);

            if (idx !== -1) {
                setOpenLoader(true);
                setTitleLoader('Generando imagen (recuerda solo se visualizaran las imagenes) ...')
                const response = await FileLegajoService.viewFileS3(info.rutaArchivo);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                updData[idx]["urlImg"] = url
                setDetailLegajo(updData);
            }
        } catch (error) {
            console.error('Error al visualizar la imagen:', error);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null)
        }
    };

    function handleMouseEnter(idx) {
        const zoomImage = document.getElementById(`zoom-img-${idx}`);
        zoomImage.style.transform = "translate(-50%, -50%) scale(1)";
        zoomImage.style.visibility = "visible";
    }

    function handleMouseLeave(idx) {
        const zoomImage = document.getElementById(`zoom-img-${idx}`);
        zoomImage.style.transform = "translate(-50%, -50%) scale(0)";
        zoomImage.style.visibility = "hidden";
    }

    return (
        <div>
            {/* {imageUrl ? <img src={imageUrl} alt="Imagen de Archivo" width={50} /> : <p>No hay imagen disponible</p>} */}
            <div className='row align-items-end'>
                <div className='col-5'>
                    <CustomInput
                        placeholder={"Fecha Inicio"}
                        title={"Fecha Inicio"}
                        type={'date'}
                        value={dateInit}
                        onChange={(e) => setDateInit(e.target.value)}
                    />
                </div>
                <div className='col-5'>
                    <CustomInput
                        placeholder={"Fecha Fin"}
                        title={"Fecha Fin"}
                        type={'date'}
                        value={dateEnd}
                        onChange={(e) => setDateEnd(e.target.value)}
                    />
                </div>
                <div className='col-5'>
                    <CustomSelect
                        placeholder={'Seleccionar Tipo'}
                        title={"Tipo"}
                        options={dataType}
                        value={valueType}
                        onChange={(value, event) => setValueType(value)}
                    />
                </div>
                <div className='col-5'>
                    <CustomSelect
                        placeholder={'Seleccionar Tipo de Legajo'}
                        title={"Tipo de Legajo"}
                        options={typeLegajo}
                        value={valueTypeLegajo}
                        onChange={(value, event) => setValueTypeLegajo(value)}
                    />
                </div>
                <div className='col-2'>
                    <CustomButton
                        key={'btn-add-file'}
                        variant={'success'}
                        value={<> <ImageSearchIcon fontSize='small' /> Buscar </>}
                        type={'button'}
                        onClick={getInfo}
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-10'>
                    <Tabs
                        value={historical}
                        onChange={handleHistorical}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="historical tabs"
                        centered
                    >
                        <Tab className='Tab-item' value={0} label="Actual" />
                        <Tab className='Tab-item' value={1} label="Historial" />
                    </Tabs>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    {historical === 0 ?
                        <div className="table-responsive body-table">
                            <table className="table table-hover table-striped table-bordered">
                                <thead className='sticky-top order-1'>
                                    <tr>
                                        {columns.map((column) => (
                                            <th
                                                scope="col"
                                                className='header-table'
                                                key={column.id}
                                                style={{
                                                    textAlign: column.align,
                                                    width: column.width,
                                                    maxWidth: column.width
                                                }}
                                            >
                                                {column.label}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {detailLegajo?.map((row, indexRow) => {
                                        return (
                                            <tr key={'row' + indexRow} style={{ whiteSpace: 'nowrap' }}>
                                                {columns.map((column, indexColumn) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <td key={'col' + indexColumn} align={column.align}>
                                                            {column.id === 'nota' ?
                                                                <NoteComplete info={value} />
                                                                : column.id === 'actions' ?
                                                                    <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                                                                        {row.urlImg ?
                                                                            // <img src={row.urlImg} alt="Imagen de Archivo" width={50} />
                                                                            <div className="image-container" key={`img-${row.id_archivoLegajo}-${indexRow}`}
                                                                                onMouseEnter={() => handleMouseEnter(indexRow)}
                                                                                onMouseLeave={() => handleMouseLeave(indexRow)}>
                                                                                <img src={row.urlImg} alt={`Imagen ${indexRow}`} width="30" />
                                                                                <img src={row.urlImg} alt={`Zoomed ${indexRow}`} className="zoom-image" id={`zoom-img-${indexRow}`} />
                                                                            </div>
                                                                            : <IconButton
                                                                                aria-label="upload"
                                                                                size="small"
                                                                                title="Desacrgar Archivo"
                                                                                onClick={() => viewFileS3(row)}
                                                                            >
                                                                                <VisibilityIcon color="info" style={{ fontSize: "medium" }} />
                                                                            </IconButton>
                                                                        }
                                                                        <IconButton
                                                                            aria-label="upload"
                                                                            size="small"
                                                                            title="Desacrgar Archivo"
                                                                            onClick={() => downloadFilesLegajo(row)}
                                                                        >
                                                                            <GetAppIcon fontSize="small" color={'success'} />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            size="small"
                                                                            title="Eliminar Archivos"
                                                                            onClick={() => deleteFileLegajo(row)}
                                                                        >
                                                                            <DeleteForeverIcon fontSize="small" color={'error'} />
                                                                        </IconButton>
                                                                    </ButtonGroup>
                                                                    : value
                                                            }
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : <div
                        // className="frame-table"
                        >
                            <div className="table-responsive body-table">
                                <table className="table table-hover table-striped table-bordered">
                                    <thead className='sticky-top'>
                                        <tr>
                                            {columnsHistorial.map((column) => (
                                                <th
                                                    scope="col"
                                                    className='header-table'
                                                    key={column.id}
                                                    style={{
                                                        textAlign: column.align,
                                                        width: column.width,
                                                        maxWidth: column.width
                                                    }}
                                                >
                                                    {column.label}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {detailLegajoAUD?.map((row, indexRow) => {
                                            rowColor = row.id_archivoLegajo !== (detailLegajoAUD[indexRow - 1] && detailLegajoAUD[indexRow - 1].id_archivoLegajo) ? (rowColor === 'table-warning' ? 'table-primary' : 'table-warning') : rowColor;
                                            return (
                                                <tr key={"row" + indexRow} style={{ whiteSpace: 'nowrap', }} className={`${rowColor}`}>
                                                    {columnsHistorial.map((column, indexColumn) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <td key={'col' + indexColumn} align={column.align}>
                                                                {column.format ?
                                                                    column.format(value)
                                                                    : (value)
                                                                }
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

const NoteComplete = ({ info }) => {
    return (
        <span>
            {/* {!mostrarDetalle && info?.slice(0, 20)} */}
            {info?.length > 20 ?
                <Tooltip
                    title={
                        <React.Fragment>
                            <Typography color="body2" style={{ fontSize: '0.8rem' }}>
                                {info}
                            </Typography>
                        </React.Fragment>
                    }
                >
                    <DescriptionIcon />
                </Tooltip>
                : info}
        </span>
    );
};


export default InformationVerifyLegajo