import React, { useState, useEffect, } from 'react'
import { Box, Button, Grid } from '@mui/material';
import GeneralService from '../../../services/general';
import UserService from '../../../services/user';
import CustomInput from '../../components/general/CustomInput';
import CustomButton from '../../components/general/CustomButton';
import DynamicTitle from '../../../utils/DynamicTitle';
import { CODES } from '../../../utils/codesHTTP'
import { simpleAlerts } from '../../../utils/alerts';
import CustomPagination from '../../components/general/CustomPagination';
import CustomRadio from '../../components/general/Radio/CustomRadio';

const columns = [
    { id: 'usuario', label: 'Usuario', width: 100, align: 'left', },
    { id: 'numdoc', label: 'Documento', width: 100, align: 'left', },
    { id: 'trabajador', label: 'Trabajador', width: 300, align: 'left', },
];

const User = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const [dataType, setDataType] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [dataUsers, setDataUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        setOpenLoader(true);
        setTitleLoader("Cargando información ...");
        Promise.all([
            getUser(),
            getUserType(),
        ]).then(() => { setOpenLoader(false); setTitleLoader(null); });
    }

    const getUser = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader("Buscando Usuarios ...");
            setDataUsers([]);
            setTotalPage(null);
            const params = {
                search: search,
                page: page,
            }
            const result = await UserService.getUser(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataUsers(result?.data?.data);
                setTotalPage(result?.data?.last_page);
            }
            return []
        } catch (error) {
            console.log(`Error en getUser: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const getUserType = async () => {
        try {
            setDataType([])
            const result = await GeneralService.getUserType();
            if (result.status === CODES.SUCCESS_200) {
                setDataType(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getType: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    useEffect(() => {
        getUserType();
    }, [page]);

    const handleKeyPress = (e) => {
        cleanVaribles();
        if (e.key === 'Enter') {
            getUser();
        }
    };

    const handleChangeSelectedUser = ({ info }) => {
        setSelectedType(info.id_tipoUsuario);
        return setSelectedUser(info);
    }

    const handleOnChange = (e) => {
        setSelectedType(e.target.value);
    };

    const cleanVaribles = () => {
        setDataUsers([]);
        setTotalPage(null);
        setSelectedType(null);
        setSelectedUser(null);
    }
    
    const saveProfileUser = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Guardando ...');

            const body = {
                id_usuario: selectedUser.id_usuario,
                id_tipoUsuario: selectedType,
            }
            const result = await UserService.saveProfileUser(body);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveProfileUser: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <DynamicTitle title="Mantenimiento de Usuarios" />
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8} sx={{ padding: "0 10px" }} mb={2}>
                    <div style={{ display: "flex", alignItems: "end", gap: 10 }}>
                        <div style={{ flex: "1" }}>
                            <CustomInput
                                placeholder={"Buscar por documento"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                handleKeyPress={handleKeyPress}
                            />
                        </div>
                        <CustomButton
                            title='Buscar trabajador por documento'
                            styleType={'outline'}
                            variant={'success'}
                            value={"Buscar"}
                            onClick={getUser}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "center" }} mb={2}>
                <Grid item xs={12} md={7}
                    sx={{ border: 'solid 1px gray', padding: '5px 10px', borderRadius: '25px', marginRight: '10px', }}
                >
                    <div className="table-responsive body-table">
                        <table className="table table-hover table-striped table-bordered">
                            <thead className='sticky-top' style={{ zIndex: 1 }}>
                                <tr>
                                    {columns.map((column) => (
                                        <th
                                            scope="col"
                                            className='header-table'
                                            key={column.id}
                                            style={{
                                                textAlign: column.align,
                                                width: column.width,
                                                maxWidth: column.width
                                            }}
                                        >
                                            {column.label}

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {dataUsers?.map((row, indexRow) => {
                                    return (
                                        <tr key={'row' + indexRow}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleChangeSelectedUser({ info: row })}
                                        >
                                            {columns.map((column, indexColumn) => {
                                                const value = row[column.id];
                                                return (
                                                    <td key={'col' + indexColumn} align={column.align}
                                                        style={{ backgroundColor: `${+selectedUser?.id_usuario === row?.id_usuario ? '#85A2EA' : ''}`, }}
                                                    >
                                                        {value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {totalPage > 1 &&
                        <CustomPagination
                            totalPages={totalPage}
                            page={page}
                            setPage={setPage}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={3}
                    sx={{ border: 'solid 1px gray', padding: '5px 10px', borderRadius: '25px', marginRight: '10px', }}
                >
                    {dataType?.map((row, idxRow) => {
                        return (
                            <CustomRadio
                                id={`radio-type-${idxRow}`}
                                label={row.descripcion}
                                value={row.id_tipoUsuario}
                                title={row.detalle}
                                checked={+selectedType === +row.id_tipoUsuario}
                                onChange={handleOnChange}
                            />
                        )
                    })}
                    <Button
                        type="button"
                        variant="contained"
                        size="small"
                        sx={{ marginTop: '5px' }}
                        onClick={saveProfileUser}
                    >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default User