import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class UserService {
    async getUser(queryParameters) {
        const params = { pageSize: 20, pagination: 1, ...queryParameters };
        const ruta = `${API_URL}/users`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async saveProfileUser(body) {
        const ruta = `${API_URL}/users/profile`;
        const headers = await headerRequest();
        return axios.post(ruta, body, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

}

export default new UserService();