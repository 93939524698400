import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material';
import LegajoService from '../../../services/legajo'
import GeneralService from '../../../services/general'
import CustomInput from '../../components/general/CustomInput';
import CustomButton from '../../components/general/CustomButton';
import CustomSelect from '../../components/general/CustomSelect';
import BodyTypeLegajo from '../../components/TypeLegajo/BodyTypeLegajo';
import { CODES } from '../../../utils/codesHTTP'
import DynamicTitle from '../../../utils/DynamicTitle';
import _ from 'lodash';

const TypeLegajo = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const [dataTypeLegajo, setDataTypeLegajo] = useState(null);
    const [optionTypeLegajo, setOptionTypeLegajo] = useState(null);
    const [dataType, setDataType] = useState(null);
    const [valueType, setValueType] = useState(0);
    const [dataCecos, setDataCecos] = useState(null);
    const [valueCeco, setValueCeco] = useState(-1);
    const [search, setSearch] = useState("");

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        setOpenLoader(true);
        setTitleLoader("Cargando información ...");
        Promise.all([
            getType(),
            getCecos(),
        ]).then(() => { setOpenLoader(false); setTitleLoader(null); getTypeLegajo(); });
    }

    const getTypeLegajo = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader("Listando tipos de legajo ...");
            setOptionTypeLegajo([])
            setDataTypeLegajo(null)
            const result = await LegajoService.getTypeLegajo();
            if (result.status === CODES.SUCCESS_200) {
                setDataTypeLegajo(result.data);
                handleOnChange({ value: valueType, ceco: valueCeco, data: result.data });
            }
            return []
        } catch (error) {
            console.log(`Error en getTypeLegajo: ${error}`);
        } finally {
            setOpenLoader(false); 
            setTitleLoader(null);
        }
    }

    const getType = async () => {
        try {
            setDataType([])
            const result = await GeneralService.getTipo();
            if (result.status === CODES.SUCCESS_200) {
                setDataType(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getType: ${error}`);
        }
    }

    const getCecos = async () => {
        try {
            setDataCecos([])
            const result = await GeneralService.getCecos();
            if (result.status === CODES.SUCCESS_200) {
                setDataCecos(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getCecos: ${error}`);
        }
    }

    const addTipeLegajo = () => {
        const row = {
            tipoLegajo: "",
            edit: 1,
            tipo_id: valueType,
            centroCosto_id: valueCeco,
        }
        let updInfo = _.cloneDeep(optionTypeLegajo);
        updInfo.unshift(row);
        return setOptionTypeLegajo(updInfo);
    };

    const handleOnChange = ({ value, ceco, data }) => {
        const sourceData = data || dataTypeLegajo;
        const filteredData = sourceData.filter(item => (+item.tipo_id === +value) && (+item.centroCosto_id === +ceco));
        setOptionTypeLegajo(filteredData);
        return setValueType(value);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <DynamicTitle title="Tipos de legajos" />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} md={7} sx={{ padding: "0 10px" }} mb={2}>
                    <div style={{ display: "flex", alignItems: "end", gap: 10 }}>
                        <div style={{ flex: "1" }}>
                            <CustomInput
                                placeholder={"Buscar por descripción"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <CustomButton
                            title='Buscar legajo'
                            styleType={'outline'}
                            variant={'success'}
                            value={"agregar"}
                            onClick={addTipeLegajo}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={5} xs={8} mt={2}>
                    <CustomSelect
                        placeholder={'Seleccionar Ceco'}
                        title={"Centro de Costo"}
                        options={dataCecos}
                        value={valueCeco}
                        onChange={(value, event) => {
                            setValueCeco(value);
                            handleOnChange({ value: valueType, ceco: value })
                        }}
                    />
                    <CustomSelect
                        placeholder={'Seleccionar Tipo'}
                        title={"Tipo"}
                        options={dataType}
                        value={valueType}
                        onChange={(value, event) => handleOnChange({ value: value, ceco: valueCeco })}
                    />
                    <BodyTypeLegajo
                        setOpenLoader={setOpenLoader}
                        setTitleLoader={setTitleLoader}
                        getTypeLegajo={getTypeLegajo}
                        search={search}
                        dataTypeLegajo={optionTypeLegajo}
                        setDataTypeLegajo={setOptionTypeLegajo}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TypeLegajo