import React from 'react'
import './radio.scss'

const CustomRadio = ({ id, label, value, name = 'radio-custom', title = '', checked, onChange }) => {
    return (
        <div className="radio-circle-custom" title={title}>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    )
}

export default CustomRadio