import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, } from "react-router-dom";
import './App.css';
import './assets/styles/main.scss';
import { ToastContainer, } from "react-toastify";
import AuthService from './services/auth';
import MenuService from './services/menu';
import NavBar from './app/components/frame/NavBar';
import SpinnerLoader from "./app/components/SpinnerLoad/SpinnerLoader"
import Login from './app/pages/Login/Login';
import RegisterLegajo from './app/pages/Legajo/RegisterLegajo';
import VerifyLegajoLegajo from './app/pages/VerifyLegajo/VerifyLegajoLegajo';
import ReportLegajo from './app/pages/Report/ReportLegajo';
import TypeLegajo from './app/pages/TypeLegajo/TypeLegajo';
import ChangePassword from './app/pages/ChangePassword/ChangePassword';
import { Global } from "./utils/general";
import { simpleAlerts } from './utils/alerts';
import { CODES } from './utils/codesHTTP';
import { validateUser } from './utils/function';
import User from './app/pages/Maintenance/User';

function App() {
  const info = Global.info;
  const infoTime = Global.infoTime;
  const pathInit = Global.path;
  const [userToken, setUserToken] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const [titleLoader, setTitleLoader] = useState(null);

  useEffect(() => {
    if (!userToken) {
      setUserToken(JSON.parse(validateUser()))
    } else {
      setTimeout(async () => {
        try {
          const result = await AuthService.me();
          if (result?.response?.status === CODES.BAD_TOKEN_498 ||
            result?.message === 'Network Error') {
            simpleAlerts((result?.response?.data.error || 'Sesión expirada'), 'error');
            localStorage.removeItem(info);
            setUserToken(null)
            // window.location.reload();
          }
        } catch (error) {
          console.log(`Error en getTypeLegajo: ${error}`);
        }
      });
    }
  }, [userToken]);

  return (
    <div className="App">
      <ToastContainer limit={5} position="bottom-right" closeOnClick />
      {userToken ?
        <div className="header">
          <NavBar setUserToken={setUserToken} />
          <div style={{ marginTop: '80px' }}>
            <BodyWeb
              userToken={userToken}
              setOpenLoader={setOpenLoader}
              setTitleLoader={setTitleLoader}
              pathInit={pathInit}
            />
          </div>
        </div>
        : <Login
          setUserToken={setUserToken}
          setOpenLoader={setOpenLoader}
          setTitleLoader={setTitleLoader}
        />
      }
      <SpinnerLoader
        open={openLoader}
        title={titleLoader}
      />
    </div>
  );
}

function BodyWeb({ userToken, setOpenLoader, setTitleLoader, pathInit }) {
  return (
    <div style={{ height: "100%", }}>
      <Routes>
        <Route
          path={`${pathInit || '/'}`}
          element={<Navigate to={`${pathInit || ''}/register-file`} />}
        />
        <Route path={`${(pathInit || '')}/register-file`} element={<RegisterLegajo userToken={userToken} setOpenLoader={setOpenLoader} setTitleLoader={setTitleLoader} />}></Route >
        <Route path={`${(pathInit || '')}/verify-file`} element={<VerifyLegajoLegajo userToken={userToken} setOpenLoader={setOpenLoader} setTitleLoader={setTitleLoader} />}></Route >
        <Route path={`${(pathInit || '')}/report`} element={<ReportLegajo userToken={userToken} setOpenLoader={setOpenLoader} setTitleLoader={setTitleLoader} />}></Route >
        <Route path={`${(pathInit || '')}/legajo-type`} element={<TypeLegajo userToken={userToken} setOpenLoader={setOpenLoader} setTitleLoader={setTitleLoader} />}></Route >
        <Route path={`${(pathInit || '')}/change-password`} element={<ChangePassword userToken={userToken} setOpenLoader={setOpenLoader} setTitleLoader={setTitleLoader} />}></Route >
        <Route path={`${(pathInit || '')}/user-maintenance`} element={<User userToken={userToken} setOpenLoader={setOpenLoader} setTitleLoader={setTitleLoader} />}></Route >
      </Routes>
    </div>
  )
}

export default App;
