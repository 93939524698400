import React, { useState, useEffect } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileLegajoService from '../../../../services/fileLegajo';
import CustomModal from '../../../components/general/CustomModal';
import CustomButton from '../../../components/general/CustomButton';
import InformationLegajo from '../../../components/RegisterLegajo/AddLegajo/InformationLegajo';
import InformationVerifyLegajo from '../../../components/RegisterLegajo/AddLegajo/InformationVerifyLegajo';
import { getDateOL, } from '../../../../utils/function';
import { simpleAlerts } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/codesHTTP';
import _ from 'lodash';

const rowData = {
    fecha: getDateOL(),
    tipo: null,
    nota: null,
    file: null,
};

const AddLegajo = (props) => {
    const { userToken, open, setOpen, stateModal, setStateModal, dataType, typeLegajo, infoWorker, infoGroup, setOpenLoader, setTitleLoader, } = props;

    const idUsuario = userToken?.user?.id_usuario
    const [rowsFiles, setRowsFiles] = useState(null);

    const [dateInit, setDateInit] = useState(getDateOL('', 'short', -30));
    const [dateEnd, setDateEnd] = useState(getDateOL());
    const [valueTypeLegajo, setValueTypeLegajo] = useState(null);
    const [detailLegajo, setDetailLegajo] = useState(null);
    const [detailLegajoAUD, setDetailLegajoAUD] = useState(null);

    const [valueType, setValueType] = useState(0)
    const [optionTypeLegajo, setOptionTypeLegajo] = useState([])
    const [historical, setHistorical] = useState(0)
    const handleHistorical = (event, newValue) => setHistorical(newValue);

    useEffect(() => {
        if (stateModal === 'VIEW') {
            getInfo();
        }
    }, [rowsFiles, stateModal])

    const cleanInfo = () => {
        setOpen(false);
        setRowsFiles(null);
        setStateModal(null);
        setDetailLegajo(null);
        setDetailLegajoAUD(null);
        setValueType(0);
    }

    const handleAddRow = () => {
        if (rowsFiles?.length > 19) {
            simpleAlerts("Ud. alcanzo el numero maximo de archivos de envio!", "warning");
            return
        }
        let updRows = _.cloneDeep(rowsFiles);
        if (updRows === null) {
            updRows = ([rowData]);
        } else {
            updRows.push(rowData);
        }
        return setRowsFiles(updRows);
    };

    const saveLegajos = async (e) => {
        e.preventDefault();
        try {
            setOpenLoader(true);
            setTitleLoader('Guardando...');

            const batchSize = 3;
            const totalBatches = Math.ceil(rowsFiles.length / batchSize);
            let countSuccess = 0;
            for (let i = 0; i < totalBatches; i++) {
                const start = i * batchSize;
                const end = Math.min(start + batchSize, rowsFiles.length);
                const batch = rowsFiles.slice(start, end);

                let formData = new FormData();
                batch.forEach((rowData, index) => {
                    formData.append(`datos[${index}][fecha]`, rowData.fecha);
                    formData.append(`datos[${index}][tipo]`, rowData.tipo);
                    formData.append(`datos[${index}][nota]`, (rowData.nota || ''));
                    formData.append(`datos[${index}][file]`, rowData.file);
                });

                formData.append(`id_usuario`, idUsuario);
                formData.append(`id_trabajadorEmpresa`, infoGroup?.id_trabajadorEmpresa);
                formData.append(`rucEmpresa`, infoGroup?.rucEmpresa);
                formData.append(`numdoc`, infoWorker?.numdoc);

                const result = await FileLegajoService.saveLegajos(formData);
                if (result.status === CODES.SUCCESS_200) {
                    countSuccess += result?.data?.archivos_guardados
                }
                await new Promise(resolve => setTimeout(resolve, 1500));
            }
            simpleAlerts(`Archivos guardados exitosamente (${countSuccess})`)
            cleanInfo();
        } catch (error) {
            console.log(`Error en saveLegajos: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    };

    const getInfo = async () => {
        Promise.all([
            getFilesByWorker(),
            getFilesByWorkerAUD(),
        ]).then();
    }

    const getFilesByWorker = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Buscando ...');
            const result = await FileLegajoService.getFilesByWorker(infoGroup?.id_trabajadorEmpresa, dateInit, dateEnd, (valueTypeLegajo || 0));
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.urlImg = null
                })
                setDetailLegajo(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getFilesByWorker: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const getFilesByWorkerAUD = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Buscando ...');
            const result = await FileLegajoService.getFilesByWorkerAUD(infoGroup?.id_trabajadorEmpresa, dateInit, dateEnd, (valueTypeLegajo || 0));
            if (result.status === CODES.SUCCESS_200) {
                setDetailLegajoAUD(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getFilesByWorker: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    useEffect(() => {
        setOptionTypeLegajo([]);
        setRowsFiles(null);
        setValueTypeLegajo(null);
        if (valueType) { 
            const filteredData = typeLegajo.filter(item => item.tipo_id === valueType);
            console.log("🚀🥵 ~ useEffect ~ filteredData:", filteredData)
            setOptionTypeLegajo(filteredData);
        }
    }, [valueType])

    useEffect(() => {
        if (stateModal === 'VIEW') {
            getInfo();
        }
    }, [rowsFiles, stateModal])

    useEffect(() => {
        if (stateModal === 'VIEW') {
            setDetailLegajo(null);
            setDetailLegajoAUD(null);
        }
    }, [valueType, valueTypeLegajo])

    return (
        <CustomModal
            size={'md'}
            open={open}
            handleClose={cleanInfo}
            formSubmit={saveLegajos}
            title={'Registro de legajos'}
            subTitle={'Guardado de archivo de legajos'}
            bodyModal={
                stateModal === 'UPLOAD' ?
                    <InformationLegajo
                        rowsFiles={rowsFiles}
                        setRowsFiles={setRowsFiles}
                        dataType={dataType}
                        valueType={valueType}
                        setValueType={setValueType}
                        typeLegajo={optionTypeLegajo}
                    />
                    : stateModal === 'VIEW' ?
                        <InformationVerifyLegajo
                            getInfo={getInfo}
                            dateInit={dateInit}
                            setDateInit={setDateInit}
                            dateEnd={dateEnd}
                            setDateEnd={setDateEnd}
                            dataType={dataType}
                            valueType={valueType}
                            setValueType={setValueType}
                            valueTypeLegajo={valueTypeLegajo}
                            setValueTypeLegajo={setValueTypeLegajo}
                            typeLegajo={optionTypeLegajo}
                            detailLegajo={detailLegajo}
                            setDetailLegajo={setDetailLegajo}
                            detailLegajoAUD={detailLegajoAUD}
                            setOpenLoader={setOpenLoader}
                            setTitleLoader={setTitleLoader}
                            historical={historical}
                            handleHistorical={handleHistorical}
                        />
                        : null
            }
            buttonActions={
                stateModal === 'UPLOAD' && (
                    [
                        <CustomButton
                            key={'btn-add-file'}
                            variant={'success'}
                            styleType={'outline'}
                            // value={[<AddCircleOutlineIcon key="icon" />, 'Agregar archivo']}
                            value={<> <AddCircleOutlineIcon fontSize='small' /> Agregar archivo </>}
                            type={'button'}
                            onClick={handleAddRow}
                        />,
                        <CustomButton
                            key={'btn-save'}
                            variant={'primary'}
                            value={'Guardar'}
                            styleType={'outline'}
                            type={'submit'}
                        />
                    ])
            }
        />
    )
}

export default AddLegajo