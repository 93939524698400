import React from 'react'
import { IconButton } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomInput from '../../general/CustomInput';
import CustomSelect from '../../general/CustomSelect';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const columns = [
    { id: '#', label: '#', width: 50, align: 'center', },
    { id: 'fecha', label: 'Fecha', width: 50, align: 'center', edit: true, type: 'date' },
    { id: 'tipo', label: 'Tipo Legajo', width: 150, align: 'left', edit: true, type: 'date' },
    { id: 'nota', label: 'Nota', width: 200, align: 'left', edit: true, type: 'text' },
    { id: 'actions', label: <ConstructionIcon key="icon" fontSize='small' />, width: 50, align: 'center', },
    { id: 'delete', label: <RemoveCircleOutlineIcon key="icon" fontSize='small' />, width: 20, align: 'center', },
];

const InformationLegajo = ({ rowsFiles, setRowsFiles, dataType, valueType, setValueType, typeLegajo }) => {

    const handleOnChange = (idx, key, value) => {
        let updRows = _.cloneDeep(rowsFiles);
        updRows[idx][key] = value;
        return setRowsFiles(updRows);
    }

    const handleDeleteRow = (idx) => {
        const newRows = [...rowsFiles];
        newRows.splice(idx, 1);
        return setRowsFiles(newRows);
    };

    const allowedFileTypes = ['.jpg', '.jpeg', '.png', '.pdf'];
    const handleFileChange = (e, indexRow, info) => {
        const element = e.target.files;
        const file = element[0];
        const fileExtension = file?.name?.split('.').pop().toLowerCase();

        if (!allowedFileTypes.includes(`.${fileExtension}`)) {
            simpleAlerts("Tipo de archivo no permitido")
            return;
        }

        // Actualizar el estado rowsFiles con la información del archivo cargado
        let updRows = _.cloneDeep(rowsFiles);
        updRows[indexRow].file = file;
        return setRowsFiles(updRows);
    }

    const viewAttachment = (info) => {
        const fileURL = URL.createObjectURL(info.file);
        window.open(fileURL, '_blank');
    };

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-striped table-bordered">
                    <thead className='sticky-top'>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    className='header-table'
                                    key={column.id}
                                    style={{
                                        textAlign: column.align,
                                        width: column.width,
                                        maxWidth: `${column.width}px`
                                    }}
                                >
                                    {column.id === 'tipo' ?
                                        <CustomSelect
                                            placeholder={'Seleccionar Tipo Legajo'}
                                            options={dataType}
                                            size={'small'}
                                            value={valueType}
                                            onChange={(value, event) => setValueType(value)}
                                        />
                                        : column.label
                                    }
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {rowsFiles?.map((row, indexRow) => {
                            return (
                                <tr key={'row' + indexRow} style={{ whiteSpace: 'nowrap' }}>
                                    {columns.map((column, indexColumn) => {
                                        const value = row[column.id];
                                        return (
                                            <td key={'col' + indexColumn} align={column.align}>
                                                {column.id === '#' ? indexRow + 1
                                                    : column.id === 'delete' ?
                                                        <IconButton
                                                            onClick={() => handleDeleteRow(indexRow)}
                                                            title="Eliminar archivo"
                                                        >
                                                            <RemoveCircleOutlineIcon color="error" style={{ fontSize: "medium" }} />
                                                        </IconButton>
                                                        : column.id === 'actions' ?
                                                            <div style={{ display: 'flex' }}>
                                                                <div>
                                                                    <input
                                                                        id={`fileInput_${indexRow}`}
                                                                        type="file"
                                                                        accept=".jpg, .jpeg, .png, .pdf"
                                                                        onChange={(e) => handleFileChange(e, indexRow, row)}
                                                                        style={{ display: 'none' }}
                                                                    />
                                                                    <IconButton component="label" title="Adjuntar archivo"
                                                                        onClick={() => document.getElementById(`fileInput_${indexRow}`).click()}
                                                                    >
                                                                        <AttachFileIcon color="inherit" style={{ fontSize: "medium" }} />
                                                                    </IconButton>
                                                                </div>
                                                                {row.file ?
                                                                    <IconButton
                                                                        onClick={() => viewAttachment(row)}
                                                                        title="Ver archivo"
                                                                    >
                                                                        <VisibilityIcon color="info" style={{ fontSize: "medium" }} />
                                                                    </IconButton>
                                                                    : <IconButton title="Sin archivo">
                                                                        <VisibilityOffIcon color="disabled" style={{ fontSize: "medium" }} />
                                                                    </IconButton>
                                                                }
                                                            </div>
                                                            : column.id === 'tipo' ?
                                                                <CustomSelect
                                                                    placeholder={'Seleccionar Tipo Legajo'}
                                                                    options={typeLegajo}
                                                                    value={value}
                                                                    onChange={(value, event) => handleOnChange(indexRow, column.id, value)}
                                                                />
                                                                : column.id === 'nota' ?
                                                                    <CustomInput
                                                                        placeholder={"Nota para el documento"}
                                                                        value={value}
                                                                        onChange={(e) => handleOnChange(indexRow, column.id, e.target.value)}
                                                                    />
                                                                    : column.id === 'fecha' ?
                                                                        <CustomInput
                                                                            placeholder={"Fecha del documento"}
                                                                            type={"date"}
                                                                            value={value}
                                                                            onChange={(e) => handleOnChange(indexRow, column.id, e.target.value)}
                                                                        />
                                                                        : value
                                                }
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InformationLegajo